body{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  background-color: #30302F;
}
.card-img-top{
  width: 15rem;
  height: 15rem;
  object-fit: cover;

}
.card{
transition: transform .2s; /* Animation */
margin: 0 auto;
  align-items: center;
  width: 300px;
  height: 410px;
  background-color: rgb(252, 250, 234);
}
.card:hover{
  transform: scale(1.1);
}
.tagCard{
  margin-top: 10px;
  margin-bottom: 10px;
}
.card-text{
  font-size: 12px;
}

